/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import Page from '../../components/liveStream'
import { liveVideoKSession } from '../../api_aws/streamAmg'
import heroBackground from '../../images/fgr-live-video.jpg'
import MetaData from '../../components/metadata'

const FGRLiveVideo = props => {
  return (
    <React.Fragment>
      <MetaData title="FGR Live Audio" />
      <Page
        {...props}
        pageData={props.data?.contentfulFgrLive}
        cta="Watch Now"
        background={heroBackground}
        title={
          <Flex
            sx={{
              justifyContent: 'center',
              position: 'relative',
              flexWrap: 'wrap',
            }}
            mb={3}
          >
            <Text color="primary" mr={2}>
              FGR Live
            </Text>{' '}
            Video
          </Flex>
        }
        kSessionId={liveVideoKSession}
      />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query FgrLiveVideo {
    contentfulFgrLive(title: { eq: "Video" }) {
      subscribeArticle {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
    }
  }
`

export default FGRLiveVideo
